body {
    background-color: hsl(0, 0%, 98%);
}

.noscroll {
    overflow: hidden;
    position: fixed;
}

.digest-address-link {
    margin-top: 10px;
    color: rgb(153, 153, 153);
    font-size: 12px;
    text-align: center;
}

.container-fluid {
    padding: 0px;
    min-height: 100%;

    /* Hack for IE */
    height: auto !important;
    height: 100%;

    margin: 0 auto -56px;
}

.normal {
    font-weight: normal;
}

.why-page h3.normal {
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 5px;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inner {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
}

.header {
    position: relative;

    padding: 6px 0px;
    height: 29px;

    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

    z-index: 100;
}

.navbar.footer .nav > li {
    line-height: 56px;
}

.navbar.footer .nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
}

.push {
    height: 56px;
}

.breakpoint {
    display: inline-block;
}

html {
    height: 100%;
}

body {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    line-height: 150%;
    height: 100%;
    font-weight: 300;
    font-size: 17px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.code-section ul.nav {
    margin: 0;
}

.code-section ul.nav li {
    display: inline-block;
    padding: 5px 14px;
    margin: 0;

    cursor: pointer;
}

.code-section ul.nav li.active {
    color: hsl(176, 46%, 41%);
    margin-bottom: -1px;

    border: 1px solid hsl(0, 0%, 87%);
    border-bottom: 1px solid hsl(0, 0%, 100%);
    border-radius: 4px 4px 0px 0px;
}

.code-section.no-tabs ul.nav {
    display: none;
}

.code-section .blocks {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid hsl(0, 0%, 87%);
}

.code-section.has-tabs .blocks {
    border-radius: 0px 6px 6px 6px;
}

.code-section.no-tabs .blocks {
    border-radius: 6px;
}

/* The API tabbed content tends to have a lot of code blocks,
   which look nicer against a different background */
.api-center .code-section .blocks {
    background-color: hsl(0, 0%, 98%);
}

.api-center .code-section ul.nav li.active {
    background-color: hsl(0, 0%, 98%);
    border-bottom: 1px solid hsl(0, 0%, 98%);
}

/* The Help Center tabbed content tends to be a lot short sentences.
   We also want the Help Center tabbed content to pop more. */
.help-center .code-section .blocks {
    max-width: 500px;
}

.code-section .blocks > div {
    display: none;
}

.code-section.no-tabs .blocks > div,
.code-section.has-tabs .blocks > .active {
    display: block;
}

.markdown .code-section ol {
    margin-left: 15px;
    margin-top: 10px;
}

.digest-container {
    padding-top: 100px;
}

.digest-email-container {
    display: block;
    margin: 0 auto !important;
    max-width: 700px;
    padding-bottom: 30px;
}

.digest-email-html {
    padding: 20px;
    background-color: hsl(0, 0%, 100%);
    border-radius: 5px;
}

.help .app-main {
    padding: 0;
    margin-top: 59px;
}

.app.help {
    display: inline-flex;

    box-shadow: 0px -20px 50px rgba(0, 0, 0, 0.04);
    overflow: auto;

    color: hsl(0, 0%, 27%);
}

/* Markdown processor generates lots of spurious <p></p> */
.help p:empty {
    margin: 0px;
}

.help .sidebar {
    width: 300px;
    z-index: 1;
    /* 100vh - navbar - paddingTop - paddingBottom - bottomNav */
    height: calc(100vh - 59px);

    border-right: 1px solid hsl(219, 10%, 97%);
    overflow: auto;

    background-color: hsl(153, 32%, 55%);
    color: hsl(0, 0%, 100%);

    -webkit-overflow-scrolling: touch;
}

.help .sidebar .content {
    padding: 10px 20px;
}

.help .sidebar h1,
.help .sidebar h2 {
    font-weight: 400;
}

.help .sidebar h1 {
    font-size: 1.25em;
    line-height: 1.5;
    margin-bottom: 0px;
    border-bottom: 1px solid hsla(0, 0, 100%, .6);
}

.help .sidebar h1:not(:first-of-type) {
    margin-top: 20px;
}

.help .sidebar h1:first-of-type,
.help .sidebar h1.home-link {
    border-bottom: none;
}

.help .sidebar h2 {
    font-size: 1.2em;
    line-height: 1.05;
    margin-bottom: 5px;
}

.help .sidebar.slide h2 {
    cursor: pointer;
}

.help .sidebar.slide h2::before {
    display: block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;

    content: "\f107";
    margin-right: 5px;
    float: right;

    opacity: 0.5;
}

.help .sidebar h1.home-link {
    font-size: 1em;
    margin-bottom: 17px;
}

.help .sidebar h1.home-link a::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;

    content: "\f0d9";
    margin-right: 10px;
}

.help .sidebar ul {
    margin: 5px 0px 10px 12px;
}

.help .sidebar.slide ul {
    margin-left: 19px;
}

.help .sidebar.slide ul {
    display: none;
}

.help .sidebar li {
    list-style-type: none;
    font-size: 0.95em;
    font-weight: 400;
    line-height: 1.7;

    cursor: pointer;
}

.help .sidebar li a,
.help .sidebar h1 a {
    color: inherit;
    display: block;
}

.help .sidebar a.highlighted {
    background-color: hsl(152, 40%, 42%);

    /* Extend highlight to entire width of sidebar, not just link area */
    /* This has been changed from "+" to "- -" because of issue #8403.
       If the issue has been fixed, please change this back to "+" again. */
    width: calc(100% - -20px);
    margin-left: -40px;
    padding-left: 40px;
}

.help .sidebar a:active,
.help .sidebar a:hover,
.help .sidebar a:focus,
.help .sidebar a:visited {
    outline: none;
}

.app.help .hamburger {
    display: none;
}

.markdown h1[id]::before,
.markdown h2[id]::before,
.markdown h3[id]::before,
.markdown h4[id]::before {
    display: block;
    content: " ";
    visibility: hidden;
}

.markdown h1[id]::before {
    margin-top: -30px;
    height: 30px;
}

.markdown h2[id]::before,
.markdown h3[id]::before,
.markdown h4[id]::before {
    margin-top: -10px;
    height: 10px;
}

.markdown ul,
.markdown ol {
    margin-left: 30px;
}

.markdown li {
    line-height: 150%;
}

.markdown ol {
    counter-reset: item;
    list-style: none;
}

.markdown ol > li,
.portico-landing.integrations ol > li {
    counter-increment: item;
    margin-bottom: 5px;
}

.markdown ol > li::before,
.portico-landing.integrations ol > li::before {
    content: counter(item);
    display: inline-block;
    vertical-align: top;
    padding: 3px 6.5px 3px 7.5px;
    margin-right: 5px;
    background-color: hsl(170, 48%, 54%);
    color: hsl(0, 0%, 100%);
    border-radius: 100%;
    font-size: 0.9em;
    line-height: 1.1;
    text-align: center;
}

.markdown ol > li > p,
.portico-landing.integrations ol > li > p {
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 30px);
    position: relative;
    top: -2px;
}

/* TODO: Clean up this override */
.portico-landing.integrations ol > li > p {
    max-width: calc(100% - 33px) !important;
    margin-top: 0px !important;
}

.help-center ol,
.portico-landing.integrations ol {
    margin-left: 0;
}

ol > li > div.codehilite,
.markdown ol > li > p:not(:first-child),
.portico-landing.integrations ol > li > p:not(:first-child) {
    padding-left: 24px;
    margin-left: 5px;
}

.markdown .content ol li p:not(:first-child),
.integration-instructions .help-content ol li p:not(:first-child) {
    display: block;
}

li div.codehilite {
    background-color: hsl(0, 0%, 100%);
}

.portico-landing.integrations ol ul {
    padding-left: 40px;
    margin-left: 5px;
}

.markdown ol > li > ul {
    padding-left: 20px;
}

.markdown ul > li::before {
    content: none;
}

#hubot-integrations {
    margin-top: 30px;
}

.title {
    font-family: Helvetica;
    font-size: 100px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 60px;
}

#digest-page-title {
    text-align: center;
}

.lead {
    font-weight: bold;
}

.pitch {
    width: 600px;
    max-width: 100%;
}

.help-box {
    max-width: 500px;

    padding: 10px;
    margin: 10px 0px;

    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2;

    color: hsl(0, 0%, 27%);

    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
    background-color: hsl(0, 0%, 98%);
}

.help-box .blob {
    margin-top: 0px;
}

.help-inline {
    font-weight: 400;
    font-size: 0.9rem;
}

.help-inline.text-error {
    color: hsl(1, 54%, 61%);
}

a.title {
    color: hsl(0, 0%, 0%);
}

a.title:hover {
    color: hsl(0, 0%, 50%);
    text-decoration: none;
}

.fakecontrol {
    padding-top: 5px;
    font-weight: bold;
}

img.screenshot {
    /* This makes it so screenshots are still shown
    even if they are larger than their span. */
    max-width: 100%;
}

.android-screenshot {
    margin-top: -30px;
    width: 221px;
}

.iphone-screenshot {
    width: 184px;
    margin: 0 32px;
}

.full-width-screenshot {
    width: 100%;
}

/* Silly easter egg for /integrations */
.integral {
    font-weight: normal;
    display: none;
}

.show-integral {
    position: relative;
}

.show-integral:hover::after {
    content: "dx";
    font-weight: normal;
    font-style: italic;
    font-size: 70%;
}

.integration {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px dashed hsl(0, 0%, 0%);
}

.integration p {
    margin-top: 2em;
}

#pw_strength {
    /* Same width as a Bootstrap default text <input> with padding */
    width: 220px;
    height: 8px;
}

#registration #pw_strength {
    width: 325px;
    margin-top: 20px;
}

.def::before {
    content: " - ";
}

.api-details {
    margin-left: 2em;
}

.api-details ul {
    list-style-type: none;
}

.api-details dd {
    margin-bottom: 1em;
}

.python {
    display: none;
}

.commandline {
    display: none;
}

@media print {
    #portico-area {
        margin-left: 1.5em;
    }
}

.landing-page {
    padding: 2em 0em;
}

.landing-page-bottom {
    padding-bottom: 2em;
}

.landing-page h2,
.landing-page h4 {
    font-weight: lighter;
}

.landing-page p {
    font-size: 120%;
}

.laptop-image {
    width: 787px;
    height: 414px;
    background-image: url('/static/images/landing-page/laptop.png');
    background-size: contain;
}

.laptop-screen {
    position: relative;
    top: 32px;
    width: 507px;
}

.platform-icon {
    width: 33%;
}

.portico-page h1 {
    font-weight: 300;
}

.shaded-background {
    background-color: hsl(0, 0%, 93%);
}

.darker-background {
    background-color: hsl(0, 0%, 28%);
    color: hsl(0, 0%, 100%);
}

.alert-hidden {
    display: none;
}

label.text-error {
    display: inline;

    padding-left: 5px;
}

input.text-error {
    border-color: hsl(0, 100%, 50%);
    outline-color: hsl(0, 100%, 50%);
}

.portico-container {
    position: relative;
}

.portico-container .if-zulip-electron {
    display: none;
}

/* detect if the platform is `ZulipDesktop` and if so, hide this tag! */
.portico-container[data-platform="ZulipElectron"] .if-zulip-electron {
    display: block;
}

.footer {
    margin: 20px;
    z-index: 100;
    width: calc(100% - 40px);

    text-align: center;
}

.footer .footer-navigation {
    margin-left: 0px;
    margin-right: 0px;
}

/* -- new footer -- */
.footer {
    font-size: 0.9em;
}

.footer a {
    color: inherit !important;
}

.footer section {
    width: calc(25% - 40px);
    margin: 0px 20px;
    max-width: 150px;

    display: inline-block;
    vertical-align: top;

    text-align: left;
}

.footer section h3 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.2;

    margin-bottom: 5px;
}

.footer section ul {
    color: hsl(0, 0%, 67%);

    list-style-type: none;
    margin-left: 0;

    font-weight: 400;
}

/* -- end new footer -- */

.header {
    padding: 15px 0;
}

.header-main .logo {
    display: block;
    text-decoration: none;
    position: relative;
    line-height: 0.8;
}

.header-main .logo .light {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;

    font-size: 1.2rem;
    font-weight: 400;
    color: hsl(0, 0%, 20%);
    opacity: 0.5;
}

.header-main .logo .brand-logo {
    width: 25px;
    margin-top: 3px;
}

.header-main .logo span {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    margin-left: 10px;

    color: hsl(0, 0%, 100%);
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.header-main .portico-logo {
    height: 28px;
    width: auto;
    padding: 6px 0px 6px 0px;
}

.portico-header .dropdown {
    position: relative;
    display: inline-block;
}

.portico-header .dropdown ul {
    display: none;

    position: absolute;
    right: 0px;
    margin: 10px 0 0 0;
    list-style-type: none;
    width: 200px;

    background-color: hsl(0, 0%, 100%);
    padding: 5px 0px;

    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 87%);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.portico-header .dropdown.show ul {
    display: block;
}

.portico-header .dropdown ul::before {
    content: "\25B2";

    position: absolute;
    top: -4px;
    right: 9px;

    font-size: 0.5em;
    color: hsl(0, 0%, 87%);

    line-height: 0;

    -webkit-transform: scale(1.8, 1);
}

.portico-header .dropdown ul li {
    display: list-item;
    margin: 0;
    padding: 3px 10px;
    text-align: left;

    opacity: 1;

    cursor: pointer;
}

.portico-header .dropdown ul li:hover {
    background-color: hsl(190, 10%, 90%);
    transition: none;
}

.portico-header .dropdown ul li a {
    display: block;
    margin: 2px 0px;
    padding: 0px;

    transition: none;
    font-size: 0.9em;
    font-weight: 400;

    color: hsl(0, 0%, 27%);
}

.portico-header .dropdown ul li:hover a {
    background-color: transparent;
}

.portico-header .dropdown ul li a i {
    margin-right: 5px;
}

.portico-header .dropdown-pill {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left: 10px;
    font-weight: 400;
    cursor: pointer;
}

.portico-header .dropdown-pill .realm-name {
    display: inline-block;
    vertical-align: top;

    margin: 0px 5px 0px -4px;
    padding: 1px 0px 1px 5px;

    font-size: 0.9em;
    font-weight: 600;

    line-height: 1.6;
}

.portico-page-container .portico-header .dropdown-pill .realm-name {
    margin-left: -3px;
}

.portico-header .dropdown-pill .realm-name i.fa {
    position: relative;
    top: -2px;

    font-size: 0.6em;
    margin-left: 5px;

    opacity: 0.5;
    transition: all 0.2s ease;
}

.portico-header .dropdown-pill:hover .realm-name i.fa {
    opacity: 1;
}

.portico-header .dropdown-pill .header-realm-icon {
    width: 26px;
    height: 26px;
    vertical-align: top;
    border-radius: 4px;
}

.app {
    width: 100%;
    z-index: 99;
}

.main-headline {
    background-color: hsl(218, 84%, 85%);
    background: url("/static/images/landing-page/gg.jpg");
    background-position: center;
    background-size: cover;
}

a.bottom-signup-button {
    color: hsl(0, 0%, 100%) !important;
    text-decoration: none !important;
    font-size: 20px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 300;
}

.signup-signature {
    margin-top: 20px;
    padding-bottom: 50px;
}

.devlogin_subheader {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 0px;
    text-align: center;
    font-size: 16px;
}

.portico-page-header {
    font-weight: 300;
    font-size: 35px;
    display: block;
    padding-top: 40px;
    margin-bottom: 20px;
}

.portico-page-header .portico-page-header-icon {
    margin-right: 12px;
}

.portico-page-header a,
.portico-page-header a:hover {
    color: hsl(0, 0%, 0%);
    text-decoration: none;
    display: inline-block;
}

.portico-large-text {
    font-size: 16px;
    line-height: 20px;
}

.portico-secondary-header {
    font-weight: 300;
    font-size: 25px;
}

.table.table-striped {
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.team-profiles {
    /* Compensate for gutter width */
    margin: -10px;
    margin-bottom: 3px;
}

.team .profile .profile-name {
    font-weight: 600;
}

.team .profile .profile-role {
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 400;
}

.team .bdfl {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.team .bdfl .profile-picture {
    flex: 0 auto;
    width: 200px;
    margin: 10px;
}

.team .bdfl .profile-picture > img {
    height: auto;
    border-radius: 5px;
}

.team .bdfl .profile-information {
    flex: 1;
    /* Wrap when screen is small to prevent very short line */
    min-width: 300px;
    font-size: 1.4em;
    margin: 10px 10px 0 10px;
}

.team .bdfl .profile-description {
    margin-top: 5px;
    font-weight: 400;
    font-size: 0.8em;
    opacity: 0.8;
    p {
        /* Avoid double-applying the 1.2em font-size here */
        font-size: 1em;
    }
}

.team input {
    display: none;
}

.team label {
    font-size: initial;
    display: inline-block;
    padding: 10px;
    border: 1px solid transparent;
    margin: 0 0 -1px 0;
}

.team label:hover {
    cursor: pointer;
}

.team input:checked + label {
    border: 1px solid hsl(0, 0%, 93%);
    border-top: 2px solid hsl(80, 61%, 50%);
    border-bottom-color: hsl(0, 0%, 100%);
}

.contributors-list {
    margin-left: -40px;
    width: calc(100% - -80px);
}

.contributors {
    display: none;
}

/* Activated .contributors */
input#total:checked ~ #tab-total,
input#server:checked ~ #tab-server,
input#desktop:checked ~ #tab-desktop,
input#mobile:checked ~ #tab-mobile,
input#python-zulip-api:checked ~ #tab-python-zulip-api,
input#zulip-js:checked ~ #tab-zulip-js,
input#zulipbot:checked ~ #tab-zulipbot,
input#terminal:checked ~ #tab-terminal {
    border-top: 1px solid hsl(0, 0%, 93%);
    padding-top: 20px;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.contributors .person a {
    color: inherit;
}

.contributors .person {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 93%);
    border-radius: 4px;
    transition: all 0.3s ease;
}

.contributors .person:hover {
    border: 1px solid hsl(0, 0%, 73%);
}

.contributors .person .avatar {
    width: 60px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.contributors .person .info {
    width: 50%;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.avatar_img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    border-radius: 20%;
}

.tab-loading,
.last-updated {
    color: hsl(0, 0%, 67%);
    font-size: 0.9em !important;
    padding-top: 30px;
    text-align: center;
}

.history .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.history .sponsors .sponsor-picture img {
    height: 170px;
    margin: 0 20px 0 20px;
    pointer-events: none;
}

.integration-instruction-block .integration-lozenge {
    float: left;
    margin-top: 5px;
}

.integration-lozenge .square-wrapper {
    height: 80px;
}

.integration-lozenge-static:hover {
    box-shadow: none;
    background-color: hsl(0, 0%, 93%);
}

.integration-lozenge .integration-link:hover {
    text-decoration: none;
}

.integration-lozenge .integration-label {
    display: block;
    text-align: center;
    padding-bottom: 18px;
    padding-top: 10px;
}

@media (min-width: 768px) {
    .integration-instructions {
        margin-left: 220px;
    }

    #integration-list-link {
        top: 200px;
        left: 30px;
    }
}
.api-page-header,
.authors-page-header,
.integrations-page-header,
.register-page-header {
    font-weight: 300;
    font-size: 40px;
    display: block;
    margin: auto;
    width: 360px;
    text-align: center;
    margin-bottom: 50px;
}

.register-page-header {
    text-align: left;
}

.login-page-header {
    width: 100%;
}

.login-page-subheader {
    font-weight: 300;
    font-size: 24px;
    display: block;
    margin: auto;
    width: 300px;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 50px;
}

.api-page-header,
.authors-page-header,
.integrations-page-header {
    padding-top: 56px;
}

.integrations-page-header,
.register-page-header,
.api-page-header {
    line-height: 42px;
    width: auto;
    margin-bottom: 10px;
}

.apps-muted {
    font-weight: 300;
    font-size: 20px;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
}

.integrations-page-header,
.api-page-header {
    text-align: left;
}

.integrations-page-header {
    font-size: 25px;
    padding-top: 20px;
}

.integration-instruction-block {
    position: relative;
}

#integration-list-link {
    position: absolute;
    text-align: center;
    display: none;
    margin-left: 5px;
}

#integration-list-link span {
    display: inline-block;
    margin-left: 5px;
}

.integrations-page-header .integrations-icon {
    font-size: 22px;
    margin-right: 10px;
}

.apps-instructions-header {
    font-weight: 300;
    font-size: 25px;
}

.btn-app-download {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-direct {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 8px;
    font-size: 18px;
    border-radius: 0px;
    min-width: 300px;
}

.btn-user {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(169, 44%, 54%);
    border-style: solid;
    color: hsl(170, 40%, 41%);
}

.btn-admin {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(198, 100%, 48%);
    border-style: solid;
    color: hsl(207, 89%, 54%);
}

.login-page {
    text-align: center;
}

.login-page .alert {
    width: 320px;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 15px;
    text-align: center;
}

.login-form {
    margin: auto;
    /* plus input padding. */
    width: calc(300px - -28px);
    margin-bottom: 10px;
}

.register-form {
    display: inline-block;
    text-align: center;
}

.register-button {
    margin-left: 10px;
}

.new-organization-button {
    margin-top: 10px;
}

input.new-organization-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-form .control-label,
.register-form .control-label {
    margin-bottom: 2px;
}

.login-form .direct-label {
    margin-top: 50px;
    margin-bottom: 6px;
}

.login-form .control-group,
.register-form .control-group {
    margin-right: 10px;
}

.login-forgot-password {
    float: right;
    line-height: 40px;
    position: relative;
    right: -15px;
    font-weight: 300;
}

.login-sso,
.login-social {
    max-width: 100%;
    min-width: 300px;
    margin: auto;
    text-align: center;
    margin-top: 10px;
}

.main-headline-container {
    position: relative;
    height: 100%;
    padding-top: 40px !important;
}

.main-headline-logo {
    display: block;
    width: 200px;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
}

.main-image {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 900px;
    height: auto;
    position: relative;
    bottom: -10px;
}

.main-headline-text {
    display: block;
    text-align: center;
    color: hsl(0, 0%, 0%);
    font-weight: 300;
}

.main-headline-text .tagline {
    font-size: 40px;
    line-height: 42px;
}

.main-headline-text .footnote {
    display: block;
    font-size: 18px;
    color: hsl(0, 0%, 0%);
    margin-top: 15px;
}

.main-signup-button {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 22px;
}

.app-main,
.header-main,
.footer-main {
    min-width: 350px;
    margin: 0px auto;
    padding: 0px 20px 0px 20px;
    position: relative;
}

.footer-main {
    text-align: center;
}

.hello-main {
    max-width: none;
    min-width: 0;
    padding: 0 !important;
}

.footer-navigation {
    list-style: none;
    padding-top: 16px;
}

.footer-navigation li,
.footer-navigation li a {
    display: inline-block;
    vertical-align: middle;
    color: hsl(0, 0%, 47%);
    font-size: 0.9em;
    font-weight: 400;
    cursor: pointer;
}

.footer-navigation li:hover a {
    color: hsl(0, 0%, 27%);
    text-decoration: none;
}

.little-bullet {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.top-links {
    text-align: right;
}

.top-links a {
    display: inline-block;
    color: hsl(0, 0%, 100%);
    padding: 2px 7px 1px 8px;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.2s ease-in;
    border-radius: 4px;
}

.top-links a:hover {
    text-decoration: none;
    background-color: hsla(0, 0%, 100%, 1.0);
    color: hsl(170, 50%, 40%);
}

.centered-button {
    text-align: center;
}

.button-muted {
    display: block;
    font-size: 12px;
    color: hsl(0, 0%, 87%);
}

/* -- password reset container -- */
.password-reset {
    display: inline-block;
    text-align: left;
}

.password-reset form {
    margin: 0px;
}

.password-reset #pw_strength {
    width: 328px;
    margin-top: 20px;
}

.password-reset .control-group .control-label {
    display: block;
}

.password-reset {
    width: 328px;
}

.password-reset .pitch {
    width: auto;
}

.input-group.margin {
    margin: 10px 0px;
}

.input-group .progress {
    margin: 0;
    margin-top: 5px;
    display: inline-block;
}

.password-reset .input-group {
    margin: 15px 0px;
}

.password-reset p {
    margin: 0;
}

.password-reset .progress {
    margin: 0;
}

.password-reset #email-section .fakecontrol {
    display: inline-block;
    margin: 0;
}

.password-reset .input-group input[type=text],
.password-reset .input-group input[type=password] {
    width: calc(100% - 14px);
}

.password-reset .input-group #pw_strength {
    width: 100%;
}

.password-reset .input-group.m-t-30 {
    margin-top: 30px;
}

.center-container {
    min-height: calc(100vh - 94px);
    min-height: 700px;
    display: flex;
    align-items: center;
    -wekbit-box-align: center;
    justify-content: center;
    text-align: center;
}

.center-block .submit-button-box .controls {
    margin: 0px;
}

.center-block {
    text-align: left;
    display: inline-block;
}

.center-block .pitch {
    width: 100%;
    margin-bottom: 20px;
}

.center-block .control-group {
    margin-bottom: 10px;
}

.center-block .control-group label {
    width: 100%;
    display: block;
    margin: 0;
    text-align: left;
}

.center-block .control-group .controls {
    margin: 0;
    text-align: left;
}

.center-block #send_confirm input[type=text] {
    margin-top: 20px;
}

.center-block .button {
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 300;
}

/* -- markdown styling -- */
.markdown {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
}

.help .markdown {
    background-color: hsl(0, 0%, 100%);
    width: calc(100vw - 300px);
    height: calc(100vh - 59px);
}

.markdown .content {
    padding: 30px;
    max-width: 768px;
    background-color: hsl(0, 0%, 100%);
}

.markdown a {
    color: hsl(176, 46%, 41%);
    font-weight: 600;
}

.markdown strong {
    font-weight: 600;
}

.markdown h1,
.markdown h2,
.markdown h3,
.why-page h1,
.why-page h2,
.why-page h3 {
    font-weight: 700;
}

.markdown h1,
.markdown h2,
.markdown h3 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.markdown h1:hover,
.markdown h2:hover,
.markdown h3:hover {
    cursor: pointer;
}

.markdown h1:hover::after,
.markdown h2:hover::after,
.markdown h3:hover::after {
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    cursor: pointer;
    content: "\f0c1";
    margin-left: 5px;
    vertical-align: middle;
}

.markdown h1,
.why-page .main h1 {
    border-bottom: 1px solid hsl(0, 0%, 93%);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.markdown h1#using-zulip,
.markdown h1#zulip-administration {
    font-size: 1.75em;
    padding: 10px 0;
    margin-bottom: 0px;
    line-height: 100%;
}

.markdown h2,
.why-page h2 {
    font-size: 1.5em;
    line-height: 1.25;
    margin: 20px 0px 5px 0px;
}

.markdown h3,
.why-page h3 {
    font-size: 1.25em;
    line-height: 1.25;
    opacity: 1;
    margin-bottom: 7px;
}

.markdown img {
    vertical-align: top;
    box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.05);
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
}

.markdown img.inline {
    height: 1.4em;
    box-shadow: none;
}

.markdown img.emoji-small {
    width: 20px;
    box-shadow: none;
    border: none;
    vertical-align: sub;
}

.markdown img.emoji-big {
    width: 25px;
    box-shadow: none;
    border: none;
}

.integration-instructions .tip,
.markdown .warn,
.markdown .tip,
.markdown .keyboard_tip {
    position: relative;
    display: block;
    background-color: hsl(210, 22%, 96%);
    border: 1px solid hsl(210, 22%, 90%);
    border-radius: 4px;
    padding: 10px;
    margin: 5px 0px;
}

.integration-instructions .tip,
.markdown .tip,
.markdown .keyboard_tip {
    background-color: hsl(46, 63%, 95%);
    border: 1px solid hsl(46, 63%, 84%);
}

.integration-instructions .tip,
.markdown .warn p,
.markdown .tip p,
.markdown .keyboard_tip p {
    margin-bottom: 0;
}

.integration-instructions .tip::before,
.markdown .tip::before {
    display: inline;
    content: "\f0eb   Tip:  ";
    font-family: FontAwesome, "Yantramanav", Source Sans Pro;
    font-weight: 600;
}

.markdown .keyboard_tip::before {
    display: inline;
    content: "\f11c   Keyboard Shortcut:  ";
    font-family: FontAwesome, "Yantramanav", Source Sans Pro;
    font-weight: 600;
}

.integration-instructions .tip p:first-of-type,
.markdown .tip p:first-of-type,
.markdown .keyboard_tip p:first-of-type {
    display: inline;
}

.integration-instructions .help-content h3 {
    margin: 20px 0;
}

.markdown ol > li > div.keyboard_tip,
.markdown ol > li > div.tip,
.markdown ol > li > div.warn {
    margin: 5px 25px 5px;
}

.markdown ol p {
    margin: 0 0 2px;
}

.markdown .indicator {
    position: relative;
    display: inline-block;
    top: 1px;
    padding: 5px;
    border-radius: 6px;
}

.markdown .indicator.grey {
    border: 1px solid hsl(0, 0%, 80%);
}

.markdown .indicator.grey-line {
    border: 1px solid hsl(0, 0%, 80%);
    top: 2px;

    &::after {
        content: '';
        background: hsl(0, 0%, 80%);
        height: 1.5px;
        width: 6px;
        display: block;
        margin: .5px -2px;
    }
}

.markdown .indicator.orange {
    border: 1px solid hsl(29, 84%, 51%);
    background: linear-gradient(to bottom, hsla(0, 0%, 100%, 0.0) 50%, hsla(29, 84%, 51%, 1.0) 50%);
}

.markdown .indicator.green {
    border: 1px solid hsl(106, 74%, 44%);
    background-color: hsla(106, 74%, 44%, 0.3);
}

.markdown .indicator.green.solid {
    background-color: hsl(106, 74%, 44%);
}

.markdown ul {
    margin: 0px 10px 15px 25px;
}

.markdown ul li {
    margin: 5px 0px;
}

.error_page {
    padding: 20px 0px;
    min-height: calc(100vh - 290px);
    background-color: hsl(163, 42%, 85%);
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.error_page .row-fluid {
    margin-top: 60px;
}

.error_page img {
    display: block;
    clear: right;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
}

.error_page .errorbox {
    margin: auto;
    border: 2px solid hsl(163, 43%, 46%);
    max-width: 500px;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 4px hsl(163, 43%, 46%);
    font-size: 18px;
}

.error_page .errorbox.config-error {
    max-width: 750px;
}

.error_page p {
    color: hsl(0, 0%, 20%);
}

.error_page .errorcontent {
    text-align: left;
    padding: 40px 40px 30px 40px;
}

.error_page .lead {
    color: hsl(163, 49%, 44%);
    font-size: 35px;
    margin-bottom: 20px;
    line-height: normal;
}

.hourglass-img {
    width: initial !important;
    margin-bottom: 25px !important;
}

@media (max-width: 800px) {
    .app.help .markdown {
        width: 100vw;
    }

    .app.help .markdown .content {
        max-width: none;
        margin-left: 50px;
    }

    .app.help .hamburger {
        display: block;
        position: fixed;
        top: 70px;
        left: 9px;
        fill: hsl(0, 0%, 100%);
        z-index: 2;

        transition: all 0.3s ease;
        cursor: pointer;
    }

    .app.help .sidebar {
        position: fixed;
        top: 59px;
        right: calc(100vw - 50px);

        width: 100%;
        min-width: unset;
        height: calc(100vh - 59px);

        pointer-events: none;
        overflow: hidden;
        transform: translateX(0);
        transition: all 0.3s ease;
    }

    .help .sidebar .content:not(.show) {
        visibility: hidden;
    }

    .app.help .sidebar.show {
        pointer-events: initial;
        transform: translateX(calc(100% - 50px));
        overflow: auto;
        .content {
            visibility: visible;
        }
    }

    .app.help .sidebar.show + .hamburger {
        transform: translateX(calc(100vw - 50px));
    }

    .app.help .sidebar.show ~ .markdown {
        -webkit-filter: brightness(0.7);
    }

    .app-main,
    .header-main {
        min-width: auto;
    }

    .app-main {
        padding: 0;
    }

    .help .sidebar:not(.show) a.highlighted {
        background-color: transparent;
    }
}

@media (max-width: 815px) {
    .footer {
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .footer section {
        width: calc(50% - 40px);
    }
}

@media (max-width: 767px) {
    body {
        padding: 0px !important;
    }

    .input-medium,
    .input-large {
        display: block !important;
        width: 100% !important;
        min-height: 30px !important;
        margin: 0px !important;
        padding: 4px 6px !important;
        line-height: 20px !important;
        box-sizing: border-box !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .postal-envelope {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
    }

    .letter-form {
        text-align: center;
    }

    .punc {
        display: none;
    }

    .signup-section {
        display: inline-block;
        margin-top: 10px;
    }

    .line-break-desktop {
        display: none;
    }

    .contributors-list {
        width: 100%;
        margin-left: 0px;
    }
}

@media (max-height: 600px) {
    .password-container {
        margin-top: 10px;
    }
}

@media (max-width: 558px) {
    .team .bdfl .profile-name,
    .team .bdfl .profile-role {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .help .app-main {
        margin-top: 39px;
    }

    .app.help .sidebar {
        top: 39px;
        height: calc(100vh - 39px);
    }

    .app.help .hamburger {
        top: 50px;
    }

    .error_page .lead {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .markdown {
        height: calc(100vh - 39px);
        width: 100%;
    }

    .markdown .content {
        padding: 10px;
    }

    .brand.logo .light {
        display: none;
    }

    .center-container {
        display: block;
    }

    .header {
        padding: 4px 0px 6px 0px;
    }

    .header-main {
        max-width: 100vw;
        padding: 0 10px;
    }

    .markdown ol {
        margin-left: 0px;
    }

    .main-headline-text .tagline {
        font-size: 32px;
        line-height: 34px;
    }

    #apps-tabs li a {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media (max-width: 475px) {
    .footer {
        width: auto;
    }

    .footer section {
        margin: 0px 3px;
        width: calc(50% - 8px);
    }
}

@media (max-width: 400px) {
    .footer section {
        width: calc(100% - 40px);
        max-width: none;
        text-align: center;
    }
}

.emoji {
    height: 25px;
    width: 25px;
    vertical-align: text-bottom;
}

/* --- new settings styling --- */
.input-group input[type=radio],
.input-group input[type=checkbox] {
    margin: 0 10px 0 0;
}

.input-group input[type=radio] {
    position: relative;
    top: 8px;
}

.input-group label {
    padding: 5px 0px;
}

.input-group label {
    margin: 0;
    padding: 5px 0px;
}

.m-v-20 {
    margin: 20px 0px;
}

.input-group.radio {
    margin-left: 25px;
}

.input-group.grid label.inline-block {
    width: 200px;
}

.input-group.grid {
    padding: 10px 0px;
    border-bottom: 1px solid hsl(0, 0%, 87%);
}

label.label-title {
    font-weight: 600;
}

input.new-organization-button {
    margin-left: auto;
}

.input-group label.inline-block {
    /* eyeballing off-centered aesth. */
    margin-top: 1px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.button-new {
    padding: 8px 15px;
    margin: 0;
    min-width: 130px;

    font-weight: 400;

    background-color: hsl(207, 55%, 53%);
    color: hsl(0, 0%, 100%);
    outline: none;
    border: none;
    border-radius: 2px;

    cursor: pointer;
    transition: all 0.3s ease;
}

/* -- button states -- */
.button-new:hover {
    -webkit-filter: brightness(1.1);
    -moz-filter: brightness(1.1);
    filter: brightness(1.1);
}

.button-new:active {
    -webkit-filter: brightness(0.9);
    -moz-filter: brightness(0.9);
    filter: brightness(0.9);
}

.button-new.sea-green {
    background-color: hsl(177, 70%, 46%);
    color: hsl(0, 0%, 100%);
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

#find_account .form-control {
    height: 30px;
}

#find_account .btn {
    height: 40px;
    border-radius: 5px;
}

.documentation-footer {
    font-size: .85rem;
    opacity: 0.8;
}

#devtools-wrapper {
    text-align: right;
}

#devtools-registration {
    float: left;
    form {
        display: inline;
    }
}

#devtools-page {
    max-width: 800px;
    margin: 0 auto;
}

.portico-page.error {
    padding-bottom: 0px;
}

.portico-page.error .portico-page-container {
    padding: 0;
}

#third-party-apps {
    margin-top: 20px;
    padding-right: 10px;
}

.table .json-api-example {
    width: fit-content;
    max-width: 300px;
    word-wrap: break-word;
}

.table .json-api-example code {
    white-space: pre-wrap;
}
